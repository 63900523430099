html {
  font-family: Inter, sans-serif;
  background-color: #131313;
  overflow-x: hidden;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

/* hide scrollbar */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

h1 {
  font-family: sans-serif;
  font-size: 3.6em;
  margin: 0;
  font-weight: normal;
  letter-spacing: 0.05em;
}

/* header */
h2 {
  font-family: sans-serif;
  font-size: 1.733em;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

/* subheader */
h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  margin: 0;
}

/* IN USE */
h4 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 130%;
  margin: 0;
}

/* IN USE */
h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875em;
  margin: 0;
}

/* footer */
h6 {
  font-size: 0.767em;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.025em;
}

/* text */
p {
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0.025em;
}

button {
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 22px;
}

a {
  letter-spacing: 0.025em;
}

* {
  box-sizing: border-box;
}

input,
select,
textarea {
  font-size: 89%;
}

input[type='date'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

svg {
  vertical-align: top;
}
